<template>
  <div>
    <div class="top">
      <div>
        <i class='i'></i>
        <span>已做</span>
      </div>
      <div>
        <i></i>
        <span>未做</span>
      </div>
    </div>
    <div class="bottom"> 
      <div class="red"  v-for='item,index in this.answer' v-bind:key="item.id"  :class="item.answer_result==2?'red':'blue'" >
        {{index+1}}
      </div>
    </div>
    <div class="btn">
      交卷查看结果
    </div>
  </div>
</template>

<script>
import request from "../plugin/request";
export default {
  data() {
     return {
      record_id:'',
      answer:''
     }
  },
  mounted() {
    this.record_id= this.$route.query.record_id,
    this.$nextTick(() => {
      this.answerSheet()
    })
  },  
  methods: {
    async answerSheet() {
      const {data}=await request.post('/app/exam/answerSheet',{record_id:this.record_id})
      console.log(data)
      this.answer=data.answer
    }
  }
}
</script>

<style lang='scss' scoped>
.top {
  width: 100%;
  text-align:end;
  div {
    display: inline-block;
    margin-right: .4rem;
    margin-top:.4rem ;
    color:#A3A3A3 ;
    .i {
      background-color: #2775FF;
    }
   i {
     width: .2rem;   
     height: .2rem;
     display: inline-block;
     background-color: #B5B5B5;
     border-radius: 50%;
     margin-right:.04rem ;
   }
    span {
      font-size: .27rem;
    }
  }
}
.bottom {
  margin-top: .4rem;
  width: 100%;
  padding:0 .77rem;
  box-sizing: border-box;
  flex: center;
  div:nth-child(5n) {
    margin-right: 0;

  }
  .red {
    width: 1.08rem;
    height: 1.08rem;
    display: inline-block;
    text-align: center;
    line-height: 1.08rem;
    border-radius: 50%;
    font-size: .43rem;
    color: #fff;
    margin-right: .76rem;
    margin-bottom: .67rem;
    background-color:#B5B5B5
  }
  .blue {
    width: 1.08rem;
    height: 1.08rem;
    display: inline-block;
    text-align: center;
    line-height: 1.08rem;
    background-color: #2775FF;
    border-radius: 50%;
    font-size: .43rem;
    color: #fff;
    margin-right: .76rem;
    margin-bottom: .67rem;
  }
}
.btn  {
  width:3.92rem ;
  height: 1.09rem;
  border:1px solid #2775FF;
  text-align: center;
  line-height: 1.09rem;
  color:#2775FF;
  border-radius: .13rem;
  font-size: .4rem;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 1.33rem;
}
</style>